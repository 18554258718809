import React, {  useEffect } from 'react'
import '../styles/Confa.scss'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";



// import required modules
import { Navigation } from "swiper";


const imgs = [
   'https://itsports.ru/static/assets/img/ivanov-goal-cska.gif',
]
const logos = [
   'https://itsports.ru/static/assets/img/confLogo1.jpg',
   'https://itsports.ru/static/assets/img/confLogo2.jpg',
   'https://itsports.ru/static/assets/img/confLogo3.jpg',
   'https://itsports.ru/static/assets/img/confLogo4.jpg',
   'https://itsports.ru/static/assets/img/confLogo5.jpg',
   'https://itsports.ru/static/assets/img/confLogo6.jpg',
]


function Confa({lang,items}){

   let links
   let sliders
   let prevBtn
   let nextBtn
   let confa
   let btnArrow
   
   

   let componentItems = items.data.attributes.conferences
   let sizeSubArr = 4
   let windows=[]
   for(let i = 0; i<Math.ceil(componentItems.slide.length/sizeSubArr);i++){
      windows[i]=componentItems.slide.slice((i*sizeSubArr),(i*sizeSubArr)+sizeSubArr)
      
   }
   
   const colors = componentItems.slide.map(item=>(item.backGround))

   useEffect(()=>{
      
      confa =document.querySelector('.confa')
      btnArrow = document.querySelector('.confa__btn')
      links = document.querySelectorAll('.lists-confa__confa')
      sliders=document.querySelectorAll('.lists-confa__link.link-slider')
      prevBtn=document.querySelector('.swiper-button-prev.conf')
      nextBtn=document.querySelector('.swiper-button-next.conf')
      let lists = document.querySelectorAll('.lists-confa__list')
      
      const colorDefault ='#16192B'

      

      for(let i = 0; i< links.length;i++){
         links[i].addEventListener('mouseover',()=>{
            mouseover(i)
         })
         links[i].addEventListener('mouseout',()=>{
            mouseout()
         })
      }
      
      
      if(sliders){
         for(let slider of sliders){
         
            if(slider.classList.contains('swiper-slide-active')){
               if(!colors[+slider.dataset.hind]){
                  confa.style.backgroundColor =colorDefault
               }
               confa.style.backgroundColor =colors[+slider.dataset.hind]
               break
            }
         }
         for(let slider of sliders){
            slider.querySelector('.lists-confa__confa').addEventListener('mouseover',()=>{
               if(!colors[+slider.dataset.hind]){
                  confa.style.backgroundColor =colorDefault
               }
               confa.style.backgroundColor =colors[+slider.dataset.hind]
            })
            slider.querySelector('.lists-confa__confa').addEventListener('mouseout',()=>{
               if(!colors[+slider.dataset.hind]){
                  confa.style.backgroundColor =colorDefault
               }
               confa.style.backgroundColor =colors[+document.querySelector('.swiper-slide-active.lists-confa__link').dataset.hind]
            })
         }
      }
      


      nextBtn.addEventListener('click',()=>{
         
         let sliders=document.querySelectorAll('.lists-confa__link')
         for(let slider of sliders){
         
            if(slider.classList.contains('swiper-slide-active')){
               if(!colors[+slider.dataset.hind]){
                  confa.style.backgroundColor =colorDefault
               }
               confa.style.backgroundColor =colors[+slider.dataset.hind]
   
               break
            }
         }
      })
      prevBtn.addEventListener('click',()=>{
         let sliders=document.querySelectorAll('.lists-confa__link')
         for(let slider of sliders){
         
            if(slider.classList.contains('swiper-slide-active')){
               if(!colors[+slider.dataset.hind]){
                  confa.style.backgroundColor =colorDefault
               }
               confa.style.backgroundColor =colors[+slider.dataset.hind]
   
               break
            }
         }
      })
      let mouseover=(i)=>{

         if(!colors[i]){
            confa.style.backgroundColor =colorDefault
         }
         confa.style.backgroundColor =colors[i]
      }
      let mouseout=()=>{
         confa.style.backgroundColor =colorDefault
      }
      lists[0].classList.add('activet')
      let btnclick=()=>{
         lists = document.querySelectorAll('.lists-confa__list')
         btnArrow.classList.toggle('activet')
         lists.forEach(list=>{
            if(list.classList.contains('activet')){
               list.classList.remove('activet')
            }else{
               list.classList.add('activet')
            }


            
         })
      }

      btnArrow.addEventListener('click',()=>{btnclick()})
   },[links,sliders,prevBtn,nextBtn,confa,btnArrow])
   return(
      <section className='confa'>
         
            <div className='confa__title'>
            {componentItems.title}
            </div>
            <div className='confa__lists lists-confa'>
               {
                  windows.map((wind,index)=>(
                     <ul key={index} className='lists-confa__list'>
                        {
                     wind.map(item=>(
                        
                        <li key={item.id} className='lists-confa__link'>
                           <div className='lists-confa__title'>{item.title.split('\n')[0]} <p>{item.title.split('\n')[1]}</p> </div>
                              {item.title.split('\n')[0] ==='High Load Moscow' ? 
                                 <a  href={item.link} className='lists-confa__confa high-load-conf' target="_blank">

                                    <div className='lists-confa__logo'>
                                 
                                       {item.logo.data[1]&&<img className='lists-confa__logo1' src={"https://agos.cy/app"+item.logo.data[1].attributes.url} alt="logo" />}
                                       <img className='lists-confa__logo2' src={"https://agos.cy/app"+item.logo.data[0].attributes.url} alt="logo" />

                                    </div>
                                    <div className='lists-confa__date'>{item.date}</div>
                                 </a>
                                 :
                                 <a  href={item.link} className='lists-confa__confa' target="_blank">

                                    <div className='lists-confa__logo'>
                                 
                                       {item.logo.data[1]&&<img className='lists-confa__logo1' src={"https://agos.cy/app"+item.logo.data[1].attributes.url} alt="logo" />}
                                       <img className='lists-confa__logo2' src={"https://agos.cy/app"+item.logo.data[0].attributes.url} alt="logo" />

                                    </div>
                                    <div className='lists-confa__date'>{item.date}</div>
                                 </a>
                              }
                           </li>
                           ))
                        }
                     </ul>
                  ))
               }
             

              
            </div>

            <Swiper
            slidesPerView={1}
            loop={true}
            navigation={{
               nextEl: '.swiper-button-next.conf',
               prevEl: '.swiper-button-prev.conf',}
            } 
            breakpoints= {{
               992: {
                  slidesPerView:2,
               },
             }}
             
            modules={[Navigation]}  
            className='confa-swiper'>
            <div className="swiper-button-prev conf"></div>
            <div className="swiper-button-next conf"></div>
            {
               componentItems.slide.map((item,index)=>(
                  <SwiperSlide key={item.id} data-hind={index} className='lists-confa__link link-slider'>
                  <div className='lists-confa__title'>{item.title.split('\n')[0]} <p>{item.title.split('\n')[1]}</p> </div>
                  {item.title.split('\n')[0] ==='High Load Moscow' ? 
                     <a  href={item.link} className='lists-confa__confa high-load-conf' target="_blank">

                        <div className='lists-confa__logo'>
                     
                           {item.logo.data[1]&&<img className='lists-confa__logo1' src={"https://agos.cy/app"+item.logo.data[1].attributes.url} alt="logo" />}
                           <img className='lists-confa__logo2' src={"https://agos.cy/app"+item.logo.data[0].attributes.url} alt="logo" />

                        </div>
                        <div className='lists-confa__date'>{item.date}</div>
                     </a>
                     :
                     <a  href={item.link} className='lists-confa__confa' target="_blank">

                        <div className='lists-confa__logo'>
                     
                           {item.logo.data[1]&&<img className='lists-confa__logo1' src={"https://agos.cy/app"+item.logo.data[1].attributes.url} alt="logo" />}
                           <img className='lists-confa__logo2' src={"https://agos.cy/app"+item.logo.data[0].attributes.url} alt="logo" />

                        </div>
                        <div className='lists-confa__date'>{item.date}</div>
                     </a>
                  }
               </SwiperSlide>
               ))
            }
            
            </Swiper>

            <div className='confa__btn'></div>
         

      </section>

   )
}

export default Confa