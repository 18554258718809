import React from 'react'
import '../styles/WhyUs.scss'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";



// import required modules
import { Navigation } from "swiper";


function WhyUs({lang, items}) {

    let componentItems = items.data.attributes.whyUs
    
    return (
        <div className="container">
            <div className="bg-green-mask">
                <div className='why-us'>
                    <h1 className="why-us__title">{componentItems.title}</h1>
                    <div className="why-us__reasons">
                        {
                            componentItems.slide.map(item=>(
                                <div key={item.id} className="why-us__reasons__item">
                                    <div className="why-us__reasons__item-mask">
                                        <img src={"https://itsports.ru/app"+item.img.data.attributes.url} alt="whyUs" className="why-us__reasons__item--image" />
                                        <h2 className="why-us__reasons__item--title">
                                        {item.title}
                                        </h2>
                                        <p className="why-us__reasons__item--descr">
                                        {item.description}
                                        </p>
                                    </div>
                                    <img src={"https://itsports.ru/app"+item.img.data.attributes.url} alt="whyUs" className="why-us__reasons__item--image" />
                                    <h2 className="why-us__reasons__item--title">
                                    {item.title}
                                    </h2>
                                    <p className="why-us__reasons__item--descr">
                                    {item.description}

                                    </p>
                                </div>
                            ))
                        }
                        
                    </div>



                    <Swiper
            breakpoints={{
                768:{
                    slidesPerView:2
                },
                1300:{
                    slidesPerView:3
                }
            }
            }
            
            slidesPerView={1}
            loop={true}
            navigation={{
               nextEl: '.swiper-button-next.whyUs',
               prevEl: '.swiper-button-prev.whyUs',}
            } 

            modules={[Navigation]}  
            className='whyUs-swiper'>
            <div className="swiper-button-prev whyUs"></div>
            <div className="swiper-button-next whyUs"></div>
                {
                    componentItems.slide.map(item=>(
                        <SwiperSlide key={item.id} className='whyUs-swiper__box'>
                            <div key={item.id} className="why-us__reasons__item">
                                <div className="why-us__reasons__item-mask">
                                    <img src={"https://itsports.ru/app"+item.img.data.attributes.url} alt="whyUs" className="why-us__reasons__item--image" />
                                    <h2 className="why-us__reasons__item--title">
                                    {item.title}
                                    </h2>
                                    <p className="why-us__reasons__item--descr">
                                    {item.description}
                                    </p>
                                </div>
                                <img src={"https://itsports.ru/app"+item.img.data.attributes.url} alt="whyUs" className="why-us__reasons__item--image" />
                                <h2 className="why-us__reasons__item--title">
                                {item.title}
                                </h2>
                                <p className="why-us__reasons__item--descr">
                                {item.description}

                                </p>
                            </div>
                        </SwiperSlide>
                    ))
                }
                
            </Swiper>
                </div>
            </div>
        </div>
    )
}

export default React.memo(WhyUs)