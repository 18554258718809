
import React, { useEffect } from 'react'
import '../styles/Directions.scss'



function Directions({lang,items}) {
    // let initialState = {
    //         'web': {
    //             title: 'web разработка', 
    //             text: `Проводим полный цикл разработки WEB-проекта: 
    //                     от создания технического задания 
    //                     до программной реализации`
    //         },
    //         'it': {
    //             title: 'it operations',
    //             text: `Сопровождаем ИТ-инфраструктуры и ИТ-сервисы. 
    //                     В компетенции направления входят: информационная 
    //                     безопасность, дата-центр, CI/CD, сетевая 
    //                     инфраструктура, техническая поддержка`
    //         },
    //         'product': {
    //             title: 'Product management',
    //             text: `Изучаем поведение пользователей, разрабатываем 
    //                     и тестируем гипотезы, реализуем новые и 
    //                     развиваем текущие продукты для наших 
    //                     пользователей и партнеров`
    //         },
    //         'ios': {
    //             title: 'IOS и Android разработка', 
    //             text: `Создаем мобильные приложения под любую из платформ iOS, Android, Windows Phone
    //             Разрабатываем решения с использованием кросс-платформенных инструментов: Хmarin, ReactNative, PhoneGap`
    //         },
    //         'по': {
    //             title: 'ПО для Windows',
    //             text: `Создаем продукты, которые позволяют оптимизировать бизнес-процессы в организации: вести учёт материальных, финансовых средств, заказов, клиентов, а также ускоряют процесс обращения данных в компании`
    //         },
    //         'webEn': {
    //             title: 'web DEVELOPMENT', 
    //             text: `We carry out a full cycle of WEB-project development:
    //             from the creation of technical specifications
    //             to  software implementation`
    //         },
    //         'itEn': {
    //             title: 'it operations',
    //             text: `We support IT infrastructures and IT services.
    //             The competence of the direction includes: information security, data center, CI / CD, network infrastructure, technical support`
    //         },
    //         'productEn': {
    //             title: 'Product management',
    //             text: `We study user behavior, develop
    //             and test hypotheses, implement new and develop current products for our users and partners`
    //         },
    //         'iosEn': {
    //             title: 'IOS&Android DEVELOPMENT', 
    //             text: `We can create mobile applications for any
    //             from platforms: iOS, Android, Windows Phone. We can also develop solutions using cross-platform tools: Xamarin, ReactNative, PhoneGap`
    //         },
    //         'поEn': {
    //             title: 'windows software',
    //             text: `Software development allows you to optimize and streamline business processes in an organization. Moreover, thanks to the software, you can speed up the process of data circulation in the organization (which will improve the quality and speed of customer service), keep records of material, financial resources, orders and customers`
    //         },
    //     }
    
      
            
           
    
    
    //const [content, setContent] = useState(initialState['web'])
    
    //let content = initialState['web']

    // setTimeout(()=>{
        
    //     let activeTab = document.querySelector('.active-tab')
    //     let activeId = activeTab.getAttribute('data-tab')
    //     let title = document.querySelector('.directions__columns__right--tab__title')
    //     let text = document.querySelector('.directions__columns__right--tab__text')
    //     if(lang=='ru'){
    //         content = initialState[activeId]
            
    //     }else{
    //         content = initialState[activeId]
            
    //     }
    //     console.log(activeTab)
    //     title.innerHTML=content.title
    //     text.innerHTML=content.text
    // },200)
    const resF=()=>{
            if(document.querySelector('.directions__columns__right--tab.activet')){
            let wh=document.querySelector('.directions__columns__right--tab.activet').clientHeight;
            const boxR = document.querySelector('.directions__columns__right');
            const tabContainer = document.querySelector('.directions__columns__left');
            if(window.innerWidth>768){
                if(!document.querySelector('.directions__columns__right--tab.activet').classList.contains('activetbw')&&document.querySelector('.directions__columns__right--tab.activet').clientHeight>tabContainer.clientHeight){
                    document.querySelector('.directions__columns__right--tab.activet').classList.add('activetbw')
                }else{
                    if(document.querySelector('.directions__columns__right--tab.activet').classList.contains('activetbw')&&document.querySelector('.directions__columns__right--tab.activet').clientHeight<=tabContainer.clientHeight)
                    document.querySelector('.directions__columns__right--tab.activet').classList.remove('activetbw')
                }
            }else{
                if(document.querySelector('.directions__columns__right--tab.activet').classList.contains('activetbw'))
                document.querySelector('.directions__columns__right--tab.activet').classList.remove('activetbw')
            }


            if((tabContainer.clientHeight>=wh)&&window.innerWidth>768){
                boxR.style.height=tabContainer.clientHeight+'px'
                
            }else{
                
                boxR.style.height=wh+'px'
            }
        }
    }


    useEffect(() => {
        const tabContainer = document.querySelector('.directions__columns__left');
        const tabItems = document.querySelectorAll('.directions__columns__left--item')
        
        const boxR = document.querySelector('.directions__columns__right');
        const boxTab = document.querySelectorAll('.directions__columns__right--tab');
        //const title = document.querySelector('.directions__columns__right--tab__title')
        //const text = document.querySelector('.directions__columns__right--tab__text')
        
        
        tabItems[0].classList.add('active-tab')
        boxTab[0].classList.add('activet')
        if(window.innerWidth>768){
            boxR.style.height=tabContainer.clientHeight+'px'

            if(!document.querySelector('.directions__columns__right--tab.activet').classList.contains('activetbw')&&document.querySelector('.directions__columns__right--tab.activet').clientHeight>tabContainer.clientHeight)
            document.querySelector('.directions__columns__right--tab.activet').classList.add('activetbw')
        }else{
            let wh=document.querySelector('.directions__columns__right--tab.activet').clientHeight;
            boxR.style.height=wh+'px'

            if(document.querySelector('.directions__columns__right--tab.activet').classList.contains('activetbw'))
            document.querySelector('.directions__columns__right--tab.activet').classList.remove('activetbw')
        }
        
        window.addEventListener('resize',resF)
        tabContainer.addEventListener('click', (event) => {
            
            tabItems.forEach((item) => {
                

                item.style.pointerEvents='none'
                setTimeout(()=>{
                    item.style.pointerEvents='all'
                
                },600)
            })
            

            if (event.target.closest('.directions__columns__left--item')) {
                tabItems.forEach((item) => {
                    item.classList.remove('active-tab')
                })
                event.target.classList.add('active-tab')
                
                boxTab.forEach(tab=>{
                    tab.classList.remove('activet');
                })
                
                    boxTab[event.target.dataset.id].classList.add('activet')
                    
                if(!document.querySelector('.directions__columns__right--tab.activet').classList.contains('activetbw')&&document.querySelector('.directions__columns__right--tab.activet').clientHeight>tabContainer.clientHeight){
                    document.querySelector('.directions__columns__right--tab.activet').classList.add('activetbw')
                }else{
                    if(document.querySelector('.directions__columns__right--tab.activet').classList.contains('activetbw')&&document.querySelector('.directions__columns__right--tab.activet').clientHeight<=tabContainer.clientHeight)
                    document.querySelector('.directions__columns__right--tab.activet').classList.remove('activetbw')
                }



                if((tabContainer.clientHeight>=boxTab[event.target.dataset.id].clientHeight)&&window.innerWidth>768){
                    boxR.style.height=tabContainer.clientHeight+'px'
                    
                }else{
                    boxR.style.height=boxTab[event.target.dataset.id].clientHeight+'px'
                }
                    
            }
        })



        return ()=> window.removeEventListener('resize',resF)
    }, [])
    let componentItems = items.data.attributes.destinations

    
    return (
        <div id='destinations' className="container">
            <div className="directions">
                <h1 className="directions__title">{componentItems.title}</h1>
                <div style={{height:'auto'}} className="directions__columns">
                    <div className="directions__columns__left">
                        {
                            componentItems.card.map((item,index)=>(
                                <h2 key={item.id} data-id={index} className="directions__columns__left--item">{item.title}</h2>
                            ))
                        }

                    </div>
                    <div style={{height:'auto'}} className="directions__columns__right">


                        {

                            componentItems.card.map((item,index)=>(
                                
                                <div key={item.id} data-id={index} className='directions__columns__right--tab'>
                                    <h1 className='directions__columns__right--tab__title'>
                                    
                                    {item.title}
                                    </h1>
                                    <p className="directions__columns__right--tab__text">
                                    {item.description}
                                    </p>
                                </div>
                            ))
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Directions