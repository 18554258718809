import React,{useEffect,useState,useRef} from 'react'
import '../../styles/itsdescr.scss'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

// import required modules
import { Navigation } from "swiper";



const Description = ({description}) => {
  return (
    <div id='itsdescr'>
      <div className='descr-title'>
         <h2>
            {description.title}
         </h2>
      </div>

      <div className='descr-cntnr'>
         {
            description.option.map(item=>(
               <div key={item.id} className='descr-option'>
                  <div className="descr-option__img">
                     <img src={"https://itsports.ru/app"+item.img.data.attributes.url} alt="" />
                  </div>
                  <div className='descr-option__text'>
                     {
                        item.text
                     }
                  </div>
               </div>
            ))
         }
      </div>

      <div className='its-desct-mob'>
         <div className='its-desct-mob__btns'>
            <div className=' swiper-button-prev its-swiper-button-prev'></div>
            <div className='swiper-button-next its-swiper-button-next'></div>
            
         </div>
         
         <Swiper
         modules={[Navigation]} 
            loop={true}
            navigation={{
            nextEl: '.swiper-button-next.its-swiper-button-next',
            prevEl: '.swiper-button-prev.its-swiper-button-prev',}
            } 
            spaceBetween={2}>
         

            <div className="swiper-wrapper its-descr-swiper">
               {
                  description.option.map(item=>(
                     <SwiperSlide key={item.id} className="swiper-slide its-descr-swiper">
                        <div className="descr-option__img">
                           <img src={"https://itsports.ru/app"+item.img.data.attributes.url} alt="" />
                        </div>
                        <div className='descr-option__text'>
                           {
                              item.text
                           }
                        </div>
                     </SwiperSlide>
                  ))
                  
               }
               
            </div>
            
         </Swiper>
         
      </div>
      
    </div>
  )
}

export default Description