import React from 'react'
import '../styles/Loader.scss'




function Loader({loading}){
   
   // window.onload= ()=> {
   //    document.body.classList.add('main-loaded_hiding');
      
   //    window.setTimeout(function () {
   //       document.body.classList.add('main-loaded');
   //       document.body.classList.remove('main-loaded_hiding');
   //    }, 500);
      

   // }
   

   return(
   
      <div className={loading ? "preloader-bg" : "preloader-bg main-loaded_hiding"}>
         <div className="preloader-main"></div>
      </div>
      
   
   
   
   )
}


export default Loader