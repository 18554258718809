import React, { useState } from 'react'
import '../styles/Contacts.scss'
import FormVacansy from './FormVacansy'
import Documentation from '../itscout/components/Documentation'
function Contacts({lang,items,documents}) {
    
    let wantToWork = items.data.attributes.wantToWork
    let componentItems = items.data.attributes.contacts
    return (
        <div  className='container'>
            {!documents&&<WantToWorkHere lang={lang} componentItems={wantToWork} />}
            {documents&&<Documentation  documents={documents}/>}
            <div className="bg-dark-mask">
                <div id='contacts' className="contacts">
                    <div className="contacts__leftside">
                        <h1 className="contacts__title">{componentItems.title}</h1>
                        <div className="contacts__leftside--items">
                            <div className="contacts__leftside--item">
                                <div className="contacts__leftside--item__types">
                                    {
                                    componentItems.contact.map(item=>(
                                        <p key={item.id}>
                                        
                                            {item.title}
                                        </p>
                                    ))

                                    }
                                    
                                </div>

                                <div className="contacts__leftside--item__values">
                                {
                                    componentItems.contact.map(item=>(
                                        <p key={item.id}>
                                        
                                        <a href={item.link}>{item.text}</a>
                                        </p>
                                    ))

                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contacts__rightside">
                    <img className="contacts__vidos " src={`https://agos.cy/app${componentItems.image.data.attributes.url}`} alt="" />
                    {/* <video className="contacts__vidos " loop muted autoPlay ><source src='https://itsports.ru/static/assets/img/ontGif.mp4'type='video/mp4'/></video> */}
                    {/* <img className="contacts__vidos " src="https://itsports.ru/static/assets/img/ontGif.gif" alt="" /> */}
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

function WantToWorkHere({lang,componentItems}) {
    // useEffect(()=>{
    //     const btn = document.querySelector('.want-work__button')
    //     btn.addEventListener('click',()=>{
    //         handleclick(btn)
    //     })

    //     let handleclick=(el)=>{
            
    //             document.querySelector('.form').scrollIntoView({block: "start", behavior: "smooth"})
            
    //     }
    // })
    //let deal=Deal()
    const [isActive, setIsActive] = useState(false)
    
    function handleDeal(){
        setIsActive(prevState => !prevState)
    }

    let updateData = () => {
        setIsActive(prevState => !prevState)
    }
        
        
    return (
        <div className='want-work'>
            
            <h1 className="want-work__title">{componentItems.title} <img src={"https://agos.cy/app"+componentItems.logo.data.attributes.url} alt="logo" /></h1>
            <p className="want-work__text">
                {componentItems.text}
                
            </p>
            
            <button onClick={handleDeal}  className="want-work__button">{componentItems.button}</button>
            <FormVacansy isActive={isActive} updateData={updateData} lang={lang} page={''}/>
        </div>
    )
}

export default Contacts