import React,{useEffect,useState,useRef} from 'react'
import About from './components/About'
import Description from './components/Description'
import Price from './components/Price'
import Contacts from '../components/Contacts'
import Loader from '../components/Loader'
import Header from '../components/Header'
import Footer from '../components/Footer'

import FormVacansy from '../components/FormVacansy'
const Main = ({lang,items,setPlayed,toggleLang}) => {

  const[loaded,setLoaded]=useState(false)
  const[about,setAbout]=useState()
  const[description,setDescription]=useState()
  const[price,setPrice]=useState()
  const [documents,setDocuments]=useState()
  const [header,setHeader]=useState()
  const [footer,setFooter]=useState()
  useEffect(()=>{window.scrollTo(0,0)},[])
  useEffect(()=>{
    
    
    if(lang&&lang.length){
    fetch(`https://itsports.ru/app/api/itscout?locale=${localStorage.getItem('lang').includes('ru')?'ru':'en'}&populate[footer][populate][logo][fields]=url&populate[footer][populate][link][fields]&populate[footer][populate][linkimg][populate][img][fields]=url&populate[header][fields]&populate[header][populate][link][fields]&populate[header][populate][logo][fields]=url&populate[header][populate][linkimg][populate][img][fields]=url&populate[about][populate][logo][fields]=url&populate[about][populate][background][fields]=url&populate[description][populate][option][populate][img][fields]=url&populate[price][populate][background][fields]=url&populate[price][populate][btn][fields]&populate[documents][populate][btn][fields]`)
    .then((res)=>res.json())
    .then((result)=>{
      
      setAbout(result.data.attributes.about)
      setDescription(result.data.attributes.description)
      setPrice(result.data.attributes.price)
      setDocuments(result.data.attributes.documents)
      setHeader(result)
      console.log('startitsc')
      setFooter(result)
    })

    .then(()=>{
     
        setLoaded(true)
      
      
    })

    .catch((err)=>console.log(err))
  }
  },[lang])

  const [isLoading,setIsLoading]=useState(true)
  const funkL=()=>{
    setIsLoading(false)
  }
  const [isActivet, setIsActivet] = useState(false)
   let updateData = () => {
      setIsActivet(prevState => !prevState)
   }
   function handleDeal(){
   setIsActivet(prevState => !prevState)
}
  if(!loaded){
    return <Loader loading={isLoading}/>
  }else{
  return (
    <>
    <FormVacansy isActive={isActivet} updateData={updateData} lang={lang} page={'itscoutbuy'}/>
      <Loader loading={isLoading}/>
      <Header setPlayed={setPlayed} lang={localStorage.getItem('lang')} toggleLang={toggleLang} items={header} />
      
      <About about={about} funkL={funkL}/>
      <div className='container'>
      <Description description={description}/>
      
      </div>
      <Price lang={lang} price={price} handleDeal={handleDeal}/>

      
      <Contacts lang={localStorage.getItem('lang')}  items={items} documents={documents}/>
      
      
      <Footer lang={localStorage.getItem('lang')}  items={footer}/>
    </>
    
  )
}
}

export default Main