import Contacts from './Contacts'
import React, { useState, useRef } from 'react'
import Dragndrop from './Dragndrop'
import '../styles/FormVacansy.scss'

function FormVacansy({isActive, updateData, lang , page}) {
      // const [isActive, setIsActive] = useState(false)
      // setIsActive(prevState => !prevState)
      
      const [isDrop, setDrop] = useState(false)
      const [name, setName] = useState('')
      const [phone, setPhone] = useState('')
      const [email, setEmail] = useState('')
      const [url, setUrl] = useState('')
      const [check, setCheck] = useState('')
      const fileRef = useRef(null)
      const [file, setFile]=useState(false)
      
      let updateDrop = () => {
         setDrop(prevState => !prevState)
      }

      const checked=()=>{
         setCheck(prevState=>!prevState)
      }
      const handleOnChange = (event) => {
         const inputType = event.target.getAttribute('name')
         switch (inputType) {
               case "name":
                  setName(event.target.value)
                  break;
               case "phone":
                  setPhone(event.target.value)
                  break;
               case "message":
                  setUrl(event.target.value)
                  break;
               default:
                  setEmail(event.target.value)
                  break;
         }
      }
      //method='POST' action='https://itsports.ru/static/indexForm.php'

      return (
         <>
            <div onClick={updateData} className={isActive ? "grey-bg activet" : "grey-bg"}></div>
               <div className={isActive ? "form-vacancy activet" : "form-vacancy"}>
                  {page==='itscoutbuy'?
                  <h2 className="form-vacancy__title-its">
                     {lang=='ru'?'Рассчитаем Ваш заказ':'We will calculate'}
                     <br/>
                           {lang=='ru'?'и подготовим коммерческое':'your order and prepare'}
                     <br/>
                     {lang=='ru'?'предложение за 3 дня':'a commercial offer in 3 days'}

                  </h2>
                  :
                  <h1 className="form-vacancy__title">
                     {lang=='ru'?'ОТКЛИКНУТЬСЯ НА ВАКАНСИЮ':'APPLY FOR THE JOB'}

                  </h1>
                  
                  }
                  {page!=='itscoutbuy'&&
                  <h2 className="form-vacancy__title"><p>{lang=='ru'?'Расскажите о себе,':'Tell us about yourself,'}</p><p>{lang=='ru'?'и мы обязательно свяжемся с Вами':`and we'll be sure to get back to you`}</p>
                  </h2>}
                  <form className='form-vacancy__container' 
                  method='POST' action='https://jsonb.ru/indexForm.php' encType="multipart/form-data"
                  >
                     <input
                     required
                           value={name}
                           onChange={handleOnChange}
                           placeholder={lang=='ru'?'Имя':'Name'}
                           className='form-vacancy__input'
                           type="text"
                           name="name"
                     />
                     <input
                     required
                           value={phone}
                           onChange={handleOnChange}
                           placeholder='+357 (99) 999 999'
                           className='form-vacancy__input'
                           type="tel"
                           name='phone'
                     />
                     <input
                     required
                           value={email}
                           onChange={handleOnChange}
                           placeholder='E-mail'
                           className='form-vacancy__input'
                           type="email"
                           name='email'
                     />{page==='itscoutbuy'&&
                     <p className='form-vacansy__area-p'><textarea
                     required
                           className='form-vacancy__area'
                           name="message"
                           id="message"
                           
                           placeholder={lang=='ru'?'Сообщение':'Message'}
                     /></p>
                     }
                     {page!=='itscoutbuy'&&
                     <>
                     <div className='form-vacancy__container-file-input'>
                        <input  className='form-vacancy__file-input'
                           type="file"
                           style={{display:isDrop ? 'block' : 'none'}}
                           onChange={()=>setFile(true)}
                           onMouseOut={updateDrop}
                           name='file'
                           ref={fileRef}
                        />
                        <Dragndrop fileRef={fileRef.current} file={file} setFile={setFile} isDrop={isDrop} updateDrop={updateDrop} lang={lang}/>
                     </div>
                     
                     <input
                     required
                           value={url}
                           onChange={handleOnChange}
                           placeholder={lang=='ru'?'Ссылка на соцсети или резюме на HeadHunter':'Link to social networks or resume'}
                           className='form-vacancy__input inp-vac-hh'
                           type="url"
                           name='message'
                     />
                     </>}
                     <div className='form-a'>
                        <input 
                           required
                           className='form-a__input' 
                           type="checkbox" id="politics"
                           onClick={checked} value="" 
                           autoComplete="off"
                        /> 
                        <span className='form-a__span' >I agree to the processing of personal data</span>
                        
                        {/* <span className='form-a__span' >{lang=='ru'?'Я согласен с обработкой':'I agree to the processing of'} <a className='form-a__href' href="https://itsports.ru/static/assets/personal/Политика обработки персональных данных ITSports.pdf" target={'_blank'}>{lang=='ru'?'персональных данных':' personal data'}</a></span> */}
                     </div>
                     
                     <button  className='form-vacancy__button' type='submit'>{lang=='ru'?'отправить':'SEND'}</button>
                  </form>
                  
                  <button onClick={updateData}  className='form-vacancy__close-button'><img src="https://itsports.ru/static/assets/img/exit.png" alt="" /></button>

               </div>
         </>
   )
}

export default FormVacansy