import React,{useEffect,useState,useRef} from 'react'
import '../../styles/itscoutAbout.scss'
const About = ({about,funkL}) => {

   
   return (
      <div id='description' className='itsabout'>
         
      
         <div className='itsabout__container'>
            <div className='itsabout__bg'>
               <img onLoad={funkL} src={"https://itsports.ru/app"+about.background.data.attributes.url} alt="" />
            </div>


            <div className='itsabout__info'>
               <div className='itsabout__logo'>
                  <img src={"https://itsports.ru/app"+about.logo.data.attributes.url} alt="ITSCOUT" />
               </div>
               <div className='itsabout__text'>
                  <div>
                     <h2>{about.title}</h2>
                  </div>
                  <div className='itsabout__descr'>
                     {about.description}
                  </div>
               </div>
               
            </div>
         </div>
         <div className='itsabout-mob'>
               <div>
                  <h2>{about.title}</h2>
               </div>
               <div className='itsabout-mob__descr'>
                  {about.description}
               </div>
               </div>
      </div>
   )
}

export default About