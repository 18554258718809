import React, { useEffect } from 'react'
import '../styles/Footer.scss'
import ScrollIntoView from 'react-scroll-into-view'
import { Link } from 'react-router-dom'
export default function Footer({lang,items}) {
    // let punct
    // useEffect(()=>{
    //     punct = document.querySelectorAll('.footer__nav__item--link')
    //     const whatAboutUs= document.querySelector('.what-about-us')
    //     const stack= document.querySelector('.stack')
    //     const ourAwesomeTeam = document.querySelector('.ourAwesomeTeam')
    //     const whatWeGonaDo = document.querySelector('.directions')
    //     const vacansions = document.querySelector('.vacancy')
    //     const contacts = document.querySelector('.contacts')
    //         punct.forEach(a=>{
    //             a.addEventListener('click',()=>{
    //                 whereWeGo(a)
    //             })
    //         })
    //     let whereWeGo=(el)=>{
            

    //     if(el.id==='whatAboutUsFooter'){
    //         whatAboutUs.scrollIntoView({block: "start", behavior: "smooth"})
    //     }
    //     if(el.id==='awesomeTechologiesFooter'){
    //         stack.scrollIntoView({block: "start", behavior: "smooth"})
    //     }
    //     if(el.id==='whatWeGonaDoFooter'){
    //         whatWeGonaDo.scrollIntoView({block: "start", behavior: "smooth"})
    //     }

    //     if(el.id==='ourAwesomeTeamFooter'){
    //         ourAwesomeTeam.scrollIntoView({block: "start", behavior: "smooth"})
    //     }

    //     if(el.id==='vacansionsFooter'){
    //         vacansions.scrollIntoView({block: "start", behavior: "smooth"})
    //     }

    //     if(el.id==='contactsFooter'){
    //         contacts.scrollIntoView({block: "start", behavior: "smooth"})
    //     }
    //     }
    // },[punct])
    const location = window.location.href
    let componentItems = items.data.attributes.footer
    
    return (
        <div id ='footer' className="footer">
            <div className="container">
                <Link to='/'> 
                    <img className='footer__logo' src={"https://agos.cy/app"+componentItems.logo.data.attributes.url} alt="agos" />
                </Link>
                <ul className='footer__nav'>
                { componentItems.link.filter(item=>item.selector!=='vacancies').map(item=>(

                    

                    <li key={item.id} className="footer__nav__item">
                        <a className="footer__nav__item--link">
                            <ScrollIntoView  selector={"#"+item.selector}>
                                {item.text}
                            </ScrollIntoView>
                        </a>
                    </li>
                    ))

                }
                {/* {!location.includes('itsports.io')&&
                    <li className="footer__nav__item">
                        <div className='footer__itscout'>
                            <div>
                                <Link to={componentItems.linkimg.text}>
                                <img className='footer__itscout-bigimg' src={"https://agos.cy/app"+componentItems.linkimg.img.data[0].attributes.url} alt="itscout" />
                                <img className='footer__itscout-smallimg' src={"https://agos.cy/app"+componentItems.linkimg.img.data[0].attributes.url} alt="itscout" />
                                </Link>
                                
                            </div>
                        </div>
                    </li>
                } */}
                
                
                </ul>
                
            </div>
        </div>
    )
}
