import React from 'react'
import '../../styles/itsdoc.scss'
const Documentation = ({documents}) => {
  console.log(documents)
  return (
    <div id='documentation' className='itsdoc'>
      <div className='itsdoc__container'>
          <div className='itsdoc__title'>
            <h3>{documents.title}</h3>
          </div>



          <div  className='itsdoc__big'>
          <ul className='itsdoc__list-descr'>
          {documents.btn.map(item=>(
            <li key={item.id} className='itsdoc__link-descr'>
              <div className='itsdoc__description'>
                {item.description}
              </div>
              
          </li>
          ))
            
          }

          </ul>
          <ul className='itsdoc__list-btn'>
          {documents.btn.map(item=>(
            <li key={item.id} className='itsdoc__link-btn'>
              
              <div className='itsdoc__btn'>
                <a href={item.link}>{item.text}</a>
                
              </div>
          </li>
          ))
            
          }

          </ul>
          </div>

          <div  className='itsdoc__small'>
          <ul className='itsdoc__list-descr'>
          {documents.btn.map(item=>(
            <li key={item.id} className='itsdoc__link-descr'>
              <div className='itsdoc__description'>
                {item.description}
              </div>
              <div className='itsdoc__btn'>
                <a href={item.link}>{item.text}</a>
                
              </div>
          </li>
          ))
            
          }

          </ul>
          </div>
          {/* <div className='itsdoc__description'>
            {documents.description}
          </div>
          <div className='itsdoc__btn'>
            <a href={documents.btn.link}>{documents.btn.text}</a>
            
          </div>
          <div className='itsdoc__btn'>
            <a href={documents.btn.link}>{documents.btn.text}</a>
            
          </div>
          <div className='itsdoc__btn'>
            <a href={documents.btn.link}>{documents.btn.text}</a>
            
          </div> */}
      </div>
    </div>
  )
}

export default Documentation