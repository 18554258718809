import React, { useState, useEffect } from 'react'
import '../styles/AAstart.scss'
import getFetch from './fetching/getFetch'
import Loader from './Loader'
import ScrollIntoView from 'react-scroll-into-view'
const imgs = [
   'https://itsports.ru/static/assets/img/ivanov-goal-cska.gif',
]



function AAstart({lang,items,teamStart}){
   
   const [gif] = useState(imgs[0])
   // const[loaded, setLoaded] = useState(false)
   // let loadedFunc = () => {
   //    setLoaded(true)
   // }
   // let myWindow
   // console.log(myWindow)
   // useEffect(()=>{
      
   //    window.addEventListener('load',()=>{loadedFunc;console.log('hi')})
   //    return window.removeEventListener('load',()=>{loadedFunc;console.log('hi')})
   // },[window])

   // let [height, setHeight] = useState('0')
   // let [width, setWidth] = useState('0')
   // let [vis, setVis] = useState('88%')
   
   



   // const greenLogoGif=()=>{


      
   //    let logo=document.querySelector('.green-awesome-logo')
   //    console.log(logo.clientHeight)
   //    setHeight(logo.clientHeight)
   //    setWidth(logo.clientWidth)
   //    setVis('100%')
   //    setTimeout(()=>{
   //       setHeight(window.innerHeight)
   //       setWidth(window.innerWidth)
         
   //    },2000)
   // }
   // const greenLogoGifOut=()=>{
   //    setVis('88%')
   //    // let logo=document.querySelector('.green-awesome-logo')
   //    // setHeight(logo.clientHeight)
   //    // setWidth(logo.clientWidth)
   // }


   // let langHeader
   //    let btn
   //    let gifka
   //    let preloader
   //    let bgGif
   //    let headerLogos
   //    let burgerLogos
   //    let header
   //    let textMain
   // useEffect(()=>{
      
      
   //    langHeader=document.querySelector('.header__lang')
   //    btn= document.querySelector('.green-awesome-logo')
   //    gifka= document.querySelector('.green-awesome-logo-gif')
   //    preloader =document.querySelector('.preloader')
   //    bgGif= document.querySelector('.green-awesome-logo-gif-img')
   //    headerLogos = document.querySelectorAll('.header__logo-img')
   //    burgerLogos=document.querySelectorAll('.burger__logos')
   //    header = document.querySelector('.header')
   //    textMain= document.querySelector('.bg-main__text')
   //    let timer 
   //    let cir
      


      
   //    let mouseOnBtn=()=>{
         
   //       let r
   //       let circumference
   //       let circle
         

   //       if(btn.textContent=='наведи на меня' || btn.textContent=='HAVE ME'){
   //          if(btn.clientHeight>=195){
               
               
   //                circle = document.querySelectorAll('.circle-200')[0].querySelector('.progress-ring__circle');
               
               
   //          }
   //          else if(btn.clientHeight>=158){
               
   //             circle = document.querySelector('.circle-160').querySelector('.progress-ring__circle');
   //          }
   //          else if(btn.clientHeight>=135){
               
   //             circle = document.querySelector('.circle-140').querySelector('.progress-ring__circle');
   //          }
   //          else if(btn.clientHeight>=115){
               
   //             circle = document.querySelector('.circle-120').querySelector('.progress-ring__circle');
   //          }
   //          else if(btn.clientHeight>=95){
               
   //             circle = document.querySelector('.circle-100').querySelector('.progress-ring__circle');
   //          }
   //          else if(btn.clientHeight>=75){
               
   //             circle = document.querySelector('.circle-80').querySelector('.progress-ring__circle');
   //          }
   //          else if(btn.clientHeight>=45){
               
   //             circle = document.querySelector('.circle-50').querySelector('.progress-ring__circle');
   //          }
   //          else if(btn.clientHeight>=38){
               
   //             circle = document.querySelector('.circle-40').querySelector('.progress-ring__circle');
   //          }
   //          else if(btn.clientHeight>=33){
               
   //             circle = document.querySelector('.circle-37').querySelector('.progress-ring__circle');

            
               
   //          }
   //       }
   //       else{
   //          if(window.innerWidth >= 800){
   //             if(btn.clientHeight>=195){
               
               
   //                circle = document.querySelectorAll('.circle-200')[0].querySelector('.progress-ring__circle');
               
               
   //          }
   //          else if(btn.clientHeight>=158){
               
   //             circle = document.querySelector('.circle-160').querySelector('.progress-ring__circle');
   //          }
   //          else if(btn.clientHeight>=135){
               
   //             circle = document.querySelector('.circle-140').querySelector('.progress-ring__circle');
   //          }
   //          else if(btn.clientHeight>=115){
               
   //             circle = document.querySelector('.circle-120').querySelector('.progress-ring__circle');
   //          }
   //          else if(btn.clientHeight>=95){
               
   //             circle = document.querySelector('.circle-100').querySelector('.progress-ring__circle');
   //          }
   //          else if(btn.clientHeight>=75){
               
   //             circle = document.querySelector('.circle-80').querySelector('.progress-ring__circle');
   //          }
   //          else if(btn.clientHeight>=45){
               
   //             circle = document.querySelector('.circle-50').querySelector('.progress-ring__circle');
   //          }
   //          else if(btn.clientHeight>=38){
               
   //             circle = document.querySelector('.circle-40').querySelector('.progress-ring__circle');
   //          }
   //          else if(btn.clientHeight>=33){
               
   //             circle = document.querySelector('.circle-37').querySelector('.progress-ring__circle');

            
               
   //          }
   //          }else{
   //          circle = document.querySelectorAll('.circle-200')[1].querySelector('.progress-ring__circle');
   //          }
   //       }

         
         



         
         
   //       r = circle.r.baseVal.value;
   //       // circle.r.baseVal.value=btn.clientHeight/2-4
   //       // circle.cx.baseVal.value=btn.clientHeight/2
   //       // circle.cy.baseVal.value=btn.clientHeight/2 
   //       circumference = 2 * Math.PI * r;
   //       circle.style.strokeDasharray = `${circumference} ${circumference}`;
   //       circle.style.strokeDashoffset = circumference;
      
         

   

        
         
         
         
         
         
   //       let i = 1
         
   //       function setProgress(percent) {
           
   //          if(circle){
   //             let offset = circumference - percent / 100 * circumference;

   //             circle.style.strokeDashoffset = offset;
   //          }
            
   //          i=i+1
          
   //       }
         
         
       
         
   //       cir = setInterval(()=>{setProgress(i)},15)
         
         





   //       document.body.classList.add('loaded_hiding');
   //       if(document.querySelector('.green-awesome-logo__div')){
   //          document.querySelector('.green-awesome-logo__div').remove()
   //       }
   //       let div  = document.createElement('div')
   //       div.className=`green-awesome-logo__div`
   //       div.textContent=3
   //       btn.append(div)
         
         
   //       preloader.classList.add('activel')
         
         
         
   //       btn.classList.add('activetbg')
   //       timer=setInterval(()=>{
   //          let i= +div.textContent - 1
   //          div.textContent=i
            

   //          if(i === 0){
   //             clearInterval(cir)
   //             setProgress(100)
   //             langHeader.classList.add('activet')
   //             textMain.classList.add('activet')
   //             gifka.classList.add('activet')
               
               
               
              
   //             btn.classList.add('activet')

   //             bgGif.classList.add('active-img')
   //             header.classList.add('activet')
   //             headerLogos.forEach(logo=>{
   //                if(logo.classList.contains('activet')){
   //                   logo.classList.remove('activet')
   //                }else{
   //                   logo.classList.add('activet')
   //                }
   //             })
   //             burgerLogos.forEach(logo=>{
   //                // if(logo.classList.contains('activet')){
   //                //    logo.classList.remove('activet')
   //                // }else{
   //                //    logo.classList.add('activet')
   //                // }
   //                logo.classList.add('activet')
   //             })
   //          }
   //       },500)
         
         
   //    }
   //    let mouseOffBtn=()=>{
   //       clearInterval(cir)
   //       gifka.style.position='absolute'
         
   //       langHeader.classList.remove('activet')
   //       btn.classList.remove('activetbg')
   //       textMain.classList.remove('activet')
   //       document.body.classList.remove('loaded_hiding');
         
   //       if(document.querySelector('.green-awesome-logo__div')){
   //          document.querySelector('.green-awesome-logo__div').remove()
   //       }
   //       preloader.classList.remove('activel')
   //       header.classList.remove('activet')
   //       gifka.classList.remove('activet')
   //       btn.classList.remove('activet')
   //       if(headerLogos[1].classList.contains('activet')){
   //          headerLogos.forEach(logo=>{
   //             if(logo.classList.contains('activet')){
   //                logo.classList.remove('activet')
   //             }else{
   //                logo.classList.add('activet')
   //             }
   //          })
   //       }
   //       // if(burgerLogos[1].classList.contains('activet')){
   //       //    burgerLogos.forEach(logo=>{
   //       //       if(logo.classList.contains('activet')){
   //       //          logo.classList.remove('activet')
   //       //       }else{
   //       //          logo.classList.add('activet')
   //       //       }
   //       //    })
   //       // }
   //       burgerLogos.forEach(logo=>{
   //          logo.classList.remove('activet')
            
   //       })

   //       setTimeout(()=>{
   //          bgGif.classList.remove('active-img')
   //       },600)
         
   //       clearInterval(timer)
   //    }
   //    if(window.innerWidth >= 800){
         
         
         
      

   //    btn.addEventListener('mouseenter',()=>{
   //       mouseOnBtn()
   //    })

   //    btn.addEventListener('mouseleave',()=>{
   //      mouseOffBtn()
   //    })
   //    btn.removeEventListener('touchstart',()=>{
   //       mouseOnBtn()
         
   //    })
   //    btn.removeEventListener('touchend',()=>{
   //       mouseOffBtn()
         
   //    })
   //    }else{

         
         
         
         
   //       btn.removeEventListener('mouseenter',()=>{
   //          mouseOnBtn()
   //       })
   //       btn.removeEventListener('mouseleave',()=>{
   //          mouseOffBtn()
   //       })
   //       let height=btn.style.height
   //       let width=btn.style.width
   //       let left =btn.style.left
   //       let transform=btn.style.transform
   //       let val = -50
   //       let font =btn.style.fontSize
   //       btn.addEventListener('touchstart',()=>{
            
   //          if(window.innerWidth <= 800){
   //             btn.style.height=`200px`
   //             btn.style.width=`200px`
   //             btn.style.left=`50%`
   //             btn.style.transform=`translate(${val}%,${val}%)`
   //             btn.style.fontSize=`150px`
   //             document.querySelector('.comp').style.display=`none`
   //             document.querySelector('.mob').style.display=`flex`
   //          }else{
   //             document.querySelector('.comp').style.display=`flex`
   //             document.querySelector('.mob').style.display=`none`
   //          }
   //          mouseOnBtn()
   //       })
   //       btn.addEventListener('touchend',()=>{
   //          mouseOffBtn()
   //            btn.style.transform=transform
   //            btn.style.height=height
   //            btn.style.width=width
   //            btn.style.left=left
   //            btn.style.fontSize=font
   //       })



   //       let bottomVis=()=>{
   //          let bg = document.querySelector('.bg-main__transparent')
   //          let img = document.querySelector('.bg-main__gif')

   //          var scrolled = window.pageYOffset;
   //          var coords = 30;
   
   //          if (scrolled > coords) {
   //             bg.classList.add('bottom-vis')
   //             img.classList.add('bottom-vis')
   //          }
   //          if (scrolled < coords) {
   //             bg.classList.remove('bottom-vis')
   //             img.classList.remove('bottom-vis')
   //          }
   
   //       }

   //       window.addEventListener('scroll', bottomVis);
   //    }
      



      
   // },[langHeader,btn,gifka,preloader,bgGif,headerLogos,burgerLogos,header,textMain])
   
   

   let componentItems = items.data.attributes.main
   
   const [isLoading, setIsLoaded] = useState(false);
   const hideLoader = ()=>{
      
      // setTimeout(()=>{
      //    teamStart()
      // },3000)
      setIsLoaded(false)
      teamStart()
   }
   



   return (
      <>
      <Loader loading={isLoading}/>
      <div  style={{zIndex:isLoading ? `999999999`:`4`}} className="main" >
      <div  className="main-img" ><img className="main-img__img" src={`https://agos.cy/app${componentItems.gif.data.attributes.url}`} alt="gif"/></div>
         {/* <div className='awesome-logo-container'>
         <div  className="green-awesome-logo-gif" ><img className="green-awesome-logo-gif-img" src={`https://itsports.ru/app${componentItems.gif.data.attributes.url}`} alt="gif"/></div>

                     <div   className='green-awesome-logo'>
                        <div  className="preloader comp">
                              <div className='preloader__container '>
                              <svg className="progress-ring circle-200">
                                 <circle cx='100' cy='100' r='97.5' className="progress-ring__circle"></circle>
                              </svg>
                              <svg className="progress-ring circle-160">
                                 <circle cx='80' cy='80' r='77.5' className="progress-ring__circle"></circle>
                              </svg>
                              <svg className="progress-ring circle-140">
                                 <circle cx='70' cy='70' r='67.5' className="progress-ring__circle"></circle>
                              </svg>
                           
                              <svg className="progress-ring circle-120">
                                 <circle cx='60' cy='60' r='57.5' className="progress-ring__circle"></circle>
                              </svg>
                              <svg className="progress-ring circle-100">
                                 <circle cx='50' cy='50' r='47.5' className="progress-ring__circle"></circle>
                              </svg>
                              <svg className="progress-ring circle-80">
                                 <circle cx='40' cy='40' r='37.5' className="progress-ring__circle"></circle>
                              </svg>

                              <svg className="progress-ring circle-50">
                                 <circle  cx='25' cy='25' r='22.5' className="progress-ring__circle"></circle>
                              </svg>
                              <svg className="progress-ring circle-40">
                                 <circle cx='20' cy='20' r='17.5' className="progress-ring__circle"></circle>
                              </svg>
                              <svg className="progress-ring circle-37">
                                 <circle cx='18.5' cy='18.5' r='16.5' className="progress-ring__circle"></circle>
                              </svg>
                              </div>
                              
                        </div>
                        <div style={{display:'none'}} className="preloader mob">
                           <div className='preloader__container '>
                              <svg className="progress-ring circle-200">
                                 <circle cx='100' cy='100' r='98' className="progress-ring__circle"></circle>
                              </svg>
                           </div>
                        </div>
                        <div className="green-awesome-logo__title">

                           {window.innerWidth >= 800 ? componentItems.buttonDesktop : componentItems.buttonMob}
                           
                        </div>
                     </div>
                     </div> */}
         <div className="main__bg bg-main">
            {/* <div className="bg-main__transparent" >
               
               <div className="bg-main__itsports itsports-bg">

                  <div className="itsports-bg__container">
                     <img src= {"https://itsports.ru/app"+componentItems.itsports.data[1].attributes.url} alt="itsports"/>
                     <div className="itsports-bg__mob-img">
                        <img src={"https://itsports.ru/app"+componentItems.itsports.data[0].attributes.url} alt="itsports" />
                     </div>
                     
                  </div>
               </div>
            </div>
             */}
            <div className="bg-main__text">
               <div className="bg-main__text-span">
                  <div className="green-span">{componentItems.description1.split('\n')[0]}</div>
                  <div> {componentItems.description1.split('\n')[1]} {componentItems.description1.split('\n')[2]}</div>
                  <div className="green-span">{componentItems.description1.split('\n')[3]}</div>
               </div>
               
               <button >
                  <ScrollIntoView className="bg-main__text-btn" selector={"#contacts"}>
                     {componentItems.buttonDesktop}
                  </ScrollIntoView>
               </button>
            </div>
            
         </div>
         
         
            <div  className="bottom-main">
               <span className="bottom-main__span">{componentItems.description2}</span>
            </div>
      </div>
      </>
      
      )
}



export default AAstart