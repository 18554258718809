import React from 'react'

function Carret() {
    return (
        <svg  fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2.5L11.5 11.5L21 2.5" stroke="#16192B" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default React.memo(Carret)