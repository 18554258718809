import React,{useState} from 'react'
import '../../styles/itsprice.scss'
import FormVacansy from '../../components/FormVacansy'
const Price = ({price,lang,handleDeal}) => {
   
   return (
   <div id='price' className='itsprice'>
      
      <div className='itsprice__bg'>
         <img className='itsprice__bg-imgbig' src={"https://itsports.ru/app"+price.background.data[1].attributes.url} alt="" />
         <img className='itsprice__bg-imgsmall' src={"https://itsports.ru/app"+price.background.data[0].attributes.url} alt="" />
      </div>
      <div className='itsprice__container'>
         <div className='itsprice__title'>
            <h2>
               {price.title}
            </h2>
            <p>{price.info}</p>
         </div>
         <div className='itsprice__subtitle'>
            <p>
               {price.subtitle}
            </p>
            <p className='itsprice__subtitle-alert'>{price.subTitleAlert}</p>
         </div>

         <div className='itsprice__btn'>
            <button onClick={handleDeal}>
               {price.btn.text}
            </button>
            
         </div>
      </div>
    </div>
  )
}

export default Price