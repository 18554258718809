import React from 'react'
import Marquee from 'react-fast-marquee'
import '../styles/OurStack.scss'

function OutStack({lang,items}) {


    let componentItems = items.data.attributes.tech

    
    return (
        <div id='technologies' className='stack'> 
        
            <h1 className='first-line stack__title'>{componentItems.description.split('\n')[0]} <span>{componentItems.description.split('\n')[1]}</span> {componentItems.description.split('\n')[2]}</h1>
            <h1 className='second-line stack__title'>{componentItems.description.split('\n')[3]}</h1>
            <div className="stack__plan">
                {
                    componentItems.plan.map(item=>(
                        <div key={item.id} className="stack__plan__item">
                            <h2 className="stack__plan__item--title">{item.title}</h2>
                            <p className="stack__plan__item--descr">{item.description}</p>
                        </div>
                    ))
                }
                
            </div>
            <div className="stack__marquee">
                <Marquee speed={80} style={{width: '100%'}} gradient={false}>
                    {
                        componentItems.mark.map(item=>(
                            <img key={item.id} className='stack__marquee--item' src={"https://itsports.ru/app"+item.img.data.attributes.url} alt="" />
                        ))
                    }
                  
                </Marquee>
            </div>
        </div>
    )
}

export default OutStack