import React, { useState } from 'react'
import '../styles/Dragndrop.scss'


function Dragndrop({fileRef, updateDrop, isDrop,file,setFile, lang}){

   const [drag, setDrag]=useState(false)
   //const [file, setFile]=useState(false)

   function onDragStartHandler(e){
      
      e.preventDefault()

      if(!file){
         setDrag(true)
         
      }
      
   }

   function onDragLeaveHandler(e){
      e.preventDefault()
      setDrag(false)
   }

   function onDropHandler(e){
      e.preventDefault()
      let files = [...e.dataTransfer.files]
      if(files){
         setFile(true)
         fileRef.files = e.dataTransfer.files;

         const dT = new DataTransfer();

         dT.items.add(e.dataTransfer.files[0]);
         //dT.items.add(e.dataTransfer.files[3]);
      }
      setDrag(false)
   }

   return(
      <div className='dragn'>

         {drag
         ?<div className='drop-area end-drop'
         onDragStart={e=>onDragStartHandler(e)}
         onDragLeave={e=>onDragLeaveHandler(e)}
         onDragOver={e=>onDragStartHandler(e)}
         onDrop={e=>onDropHandler(e)}
         >
            
            {lang=='ru'?'Отпустите файл':'let go of '}</div>
         :<div className='drop-area'
         onMouseOver={file ? undefined : updateDrop}
         onTouchStart={file ? undefined : updateDrop}
         onDragStart={e=>onDragStartHandler(e)}
         onDragLeave={e=>onDragLeaveHandler(e)}
         onDragOver={e=>onDragStartHandler(e)}

         
         style={{backgroundColor:file ? `#ebeef057`:`inherit`}}
         >{file ? lang=='ru'?`Файл загружен`:'Loaded' : lang=='ru'?`Перетащите или загрузите файл`:'Drag&drop or download'}</div>
         }

      </div>
   )
}

export default Dragndrop