import React, { useState, useEffect } from 'react'
import '../styles/About.scss'

const slides = [
    'https://itsports.ru/static/assets/img/slider1.png',
    'https://itsports.ru/static/assets/img/slider2.png',
    'https://itsports.ru/static/assets/img/slider3.png',
    'https://itsports.ru/static/assets/img/slider4.png',
]
const imgs = [
    'https://itsports.ru/static/assets/img/arrowR.png',
 ]
// Добавить реализацию prev и next слайдов

function About() {
    const [currentSlider, setCurrentSlider] = useState(slides[0])
    const [activeIndex, setActiveIndex] = useState(0)

    const [arrow] = useState(imgs[0])


    useEffect(() => {
        const pagContainer = document.querySelector('.about__description__pagination')
        const pagItems = document.querySelectorAll('.about__pagination__button')

        function changeSlide(target) {
            const newImage = target.getAttribute('data-index')
            pagItems.forEach(item => {
                item.classList.remove('active')
            })
            if (newImage === 'prev') {
                setActiveIndex(prevState => prevState - 1)
                return
            } else if (newImage === 'next') {
                setActiveIndex(prevState => prevState + 1)
                return
            } else {
                target.classList.add('active')
                setActiveIndex(newImage)
            }
        }
    
        pagContainer.addEventListener('click', (event) => {
            if (event.target.closest('.about__pagination__button')) {
                changeSlide(event.target)
            }
        })
    }, [])

    useEffect(() => {
        if (activeIndex < 0) setActiveIndex(3)
        if (activeIndex > 3) setActiveIndex(0)
        setCurrentSlider(slides[activeIndex])
    }, [activeIndex])

    return (
        <div className="container">
            <div className='about'>
                <div className="about__description">
                    <h1 className="about__description__title">о нас</h1>
                    <p className="about__description__text">
                        ITSports предоставляет полный пакет инновационных разработок для
                        расширения и развития бизнес-процессов в спортивном секторе. Благодаря
                        нам спортивные организации выходят на новый уровень.
                    </p>
                    <div className="about__description__pagination">
                        <button data-index="prev" className="about__pagination__button slider--prev"><img className="slider--prev__arrow" src={arrow} alt=""/></button>
                        <button data-index={0} className="active about__pagination__button">1</button>
                        <button data-index={1} className="about__pagination__button">2</button>
                        <button data-index={2} className="about__pagination__button">3</button>
                        <button data-index={3} className="about__pagination__button">4</button>
                        <button data-index="next" className="about__pagination__button slider--next"><img className="slider--next__arrow" src={arrow} alt=""/></button>
                    </div>
                </div>
                <div className="about__slider">
                    <img src={currentSlider} alt='Наша команда'/>
                </div>
            </div>
        </div>
    )
}

export default About