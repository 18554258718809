import React, { useEffect, useState } from 'react'
import '../styles/OurAwesomeTeam.scss'
import SlideOurTeam from './SlideOurTeam';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Lazy } from 'swiper'
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";



// import required modules
import { Navigation } from "swiper";




function OurTeam({lang,items,isTeamReady,played,setVidFlag,vidFlag}){
   
   const [testL,setTestL]=useState(false)
   const changeTestL=()=>{
      setTimeout(() => {
         setTestL(true)
      }, 500);
      
      console.log('imload')
      //setImg(0)
   }
   const [slides,setSlides]=useState(false)
   const changeSlide=()=>{
      setSlides(true)
      console.log('d')
      //setImg(0)
   }

   useEffect(()=>{

         function playVidos(){
            //let activeSliders =document.querySelectorAll('.swiper-slider-team.swiper-slide-active')

            
               let noactiveSliders = document.querySelectorAll('.swiper-slider-team')
               
               noactiveSliders.forEach(slide=>{

                  slide.querySelector('.team-slider__vidos').setAttribute('muted','muted')

                  if(slide.classList.contains('swiper-slide-active')){
                     let vid=slide.querySelector('.team-slider__vidos')
                     var isPlaying = vid.currentTime > 0 && !vid.paused && !vid.ended && vid.readyState > vid.HAVE_CURRENT_DATA;
                     vid.classList.add('active-auto')

                     if(vid.tagName!=='IMG'&&!isPlaying){
                        vid.play()
                        //console.log(vid)
                     }
                     
                  }else{
                     let vid=slide.querySelector('.team-slider__vidos')
                  
                     vid.classList.remove('active-auto')
                     if(vid.tagName!=='IMG'){
                        vid.pause();
                     
                     }
                     
                  }
               })
            
         
            // activeSliders.forEach(el=>{
            //    let vid=el.querySelector('.team-slider__vidos')
               
            //    vid.classList.add('active-auto')
            //    vid.play()
            // })
            
            
         }
         
            
            let intr=setInterval(()=>{
               playVidos()
               
            },1000)
            return ()=>{clearInterval(intr)}
         

   },[vidFlag])
   const [img,setImg]=useState(null)
   useEffect(()=>{
      //console.log(slides)
      if(testL){
         if(slides){
            if(img!==0){

               console.log('11111')
               setImg(0)
            }
         }else{
            let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            
            if (isIOS) {
               console.log('11222')
               setImg(1)
            } else {
               console.log('11333')
               setImg(0)
            }
         }
      }
      
         
      
         
   

   },[slides,testL])

   let componentItems = items.data.attributes.team
   
   // https://itsports.ru/app/uploads/Fyodor_Frontend_razrabotchik_1_0402eb222d.mp4?updated_at=2022-07-27T14:08:11.682Z
      return (
         <div id='team' className="ourAwesomeTeam">

              <video  onLoadedMetadata={changeTestL}  onPlay={changeSlide} className="testvideo "  preload='auto' autoPlay={true} muted loop playsInline>
                  <source  src='https://agos.cy/app/uploads/Fyodor_Frontend_razrabotchik_1_0402eb222d_e2878ded70.mp4?updated_at=2023-04-12T14:10:16.289Z' type='video/mp4'/>
               </video> 
            {
               (isTeamReady)?<div>
               
               
            
               <div className="ourAwesomeTeam__title">{componentItems.title}</div>
               
               <div className="ourAwesomeTeam__slider team-slider" >
               {img!==null&&
                  <div className="team-slider__vidos-container" >
                     {/* <video className="team-slider__vidos active-teammate" autoPlay muted="muted" loop><source src={video[0]} type='video/mp4'/></video>            
                     <video className="team-slider__vidos next-teammate" autoPlay muted="muted" loop><source src={video[1]} type='video/mp4'/></video>             */}
                        
                        <Swiper 
                           
                           slidesPerView={1}
                           spaceBetween={10}
                           centeredSlides ={true}
                           loop={true}
                           loopFillGroupWithBlank={true}
                           navigation={{
                              nextEl: '.swiper-button-next.team',
                              prevEl: '.swiper-button-prev.team',}
                           } 
                           modules={[Navigation,Lazy]} 
                           breakpoints={{
                              1134: {
                                 slidesPerView: 5,
                              },
                              767: {
                                 slidesPerView: 3,
                              },
                           }}
                           
                           className="team-swiper five-teammates">
      
                           <div className="swiper-button-prev team"></div>
                           <div className="swiper-button-next team"></div>
      
                           {
                              componentItems.slide.map(item=>(
      
                                 <SwiperSlide  key={item.id} className='swiper-slider-team'>
                                       
                                       <SlideOurTeam played={false} item={item} img={img}/>
                                    
                                    
                                 </SwiperSlide>
                              ))
                           }
                           
                        </Swiper>
                        
                        
                        {/* <Swiper 
                           
                           modules={[Navigation,Lazy]} 
                           slidesPerView={1}
                           spaceBetween={0}
                           centeredSlides ={true}
                           loop={true}
                           preloadImages={false}
                           lazy={true}
                           loopFillGroupWithBlank={true}
                           navigation={{
                              nextEl: '.swiper-button-next.team',
                              prevEl: '.swiper-button-prev.team',}
                           } 
                        
                           className="team-swiper one-teammates">
      
                           <div className="swiper-button-prev team"></div>
                           <div className="swiper-button-next team"></div>
                           {
                              componentItems.slide.map(item=>(
      
                                 <SwiperSlide  key={item.id} className='swiper-slider-team'>
                                       
                                       <SlideOurTeam played={false} item={item} img={img}/>
                                    
                                    
                                 </SwiperSlide>
                              ))
                           }
                           
                           
                        </Swiper> */}
                        

                  </div>
               }
               </div>
            </div>
            :
            <div>loading...</div>
         }
         </div>
         )
   }
   




export default OurTeam